import {
    ViewChild,
    Component,
    OnDestroy,
    AfterContentInit,
} from '@angular/core';
import OF from 'src/app/models/OF';
import Phase from 'src/app/models/OF_Phase';
import OFService from 'src/app/services/main/of.service';
import MeService from 'src/app/services/me.service';
import WebsocketService from 'src/app/services/websocket.service';
import UnlinkedSession from 'src/app/models/UnlinkedSession';
import { UnlinkedSessionService } from 'src/app/services/main/unlinked-session.service';
import { UnlinkedSessionModalComponent } from '../unlinked-session-modal/unlinked-session-modal.component';
import { OfPauseModalComponent } from '../of-pause-modal/of-pause-modal.component';
import Work_Pause from 'src/app/models/Work_Pause';

declare let Notiflix: any;

@Component({
    selector: 'app-embalajes-view',
    templateUrl: './embalajes-view.component.html',
    styleUrls: ['./embalajes-view.component.scss'],
})
export class EmbalajesViewComponent {
    constructor(
        private ofService: OFService,
        private meService: MeService,
        private wsService: WebsocketService,
        private unlinkedSessionService: UnlinkedSessionService
    ) {}

    readyListener: any;

    refreshInterval: any;
    unbindWS0: any;
    unbindWS: any;
    ngAfterContentInit() {
        this.meService.addEventListener(
            'ready',
            (this.readyListener = async () => {
                this.onOfListUpdated();
            })
        );

        this.sortBy('plazo');

        this.unbindWS0 = this.wsService.on(
            'global:resync',
            async (data: any | null) => {
                if (!data) return;

                this.active_pauses = data.active_pauses as Work_Pause[];
                this.ofItemList = data.ofs as OF[];
                this.unlinkedSessionList = data.ofs as UnlinkedSession[];
                this.onFiltersChange();
                this.onOfListUpdated();
            }
        );

        this.unbindWS = this.wsService.on(
            'global:updated,of:created',
            async (data: any | null) => {
                if (!data) {
                    await this.refreshList();
                    //this.enableInteraction();
                    return;
                }

                this.active_pauses = data.active_pauses as Work_Pause[];
                this.ofItemList = data.ofs as OF[];
                this.unlinkedSessionList =
                    data.unlinked_sessions as UnlinkedSession[];
                this.onFiltersChange();
                this.onOfListUpdated();
                //this.enableInteraction();
            }
        );
        this.wsService.sendSocketMessage('global:resync');
    }

    ngOnDestroy() {
        if (this.unbindWS0) this.unbindWS0();
        if (this.unbindWS) this.unbindWS();

        if (this.readyListener)
            this.meService.removeEventListener('ready', this.readyListener);
    }

    imRunningOF = false;
    imPausing = false;
    imRunningEmbalaje = false;
    imRunningUnlinked = false;

    seachFilter = '';
    active_pauses: Work_Pause[] = [];
    ofItemList: OF[] = [];
    ofItemList_filtered: OF[] = [];

    unlinkedSessionList: UnlinkedSession[] = [];

    refreshList() {
        this.wsService.sendSocketMessage('global:resync');
    }

    onOfListUpdated() {
        this.imRunningOF = this.ofItemList.some(of =>
            of.phases.some(phase =>
                phase.active_sessions.some(
                    s =>
                        !s.is_paused &&
                        s.user.uid === this.meService.cachedBasic?.uid
                )
            )
        );

        this.imPausing = this.active_pauses.some(
            pause => pause.user?.uid === this.meService.cachedBasic?.uid
        );

        this.imRunningEmbalaje = this.ofItemList.some(of =>
            of.phases.some(
                phase =>
                    phase.name === 'embalaje' &&
                    phase.active_sessions.some(
                        s =>
                            !s.is_paused &&
                            s.user.uid === this.meService.cachedBasic?.uid
                    )
            )
        );

        this.imRunningUnlinked = this.unlinkedSessionList.some(
            session => session.user.uid === this.meService.cachedBasic?.uid
        );
    }

    disabledInteraction = false;
    disabledInteractionTimeout: any;
    disableInteraction() {
        this.disabledInteraction = true;
        this.disabledInteractionTimeout = setTimeout(
            () => (this.disabledInteraction = false),
            4000
        );
    }

    enableInteraction() {
        this.disabledInteraction = false;
        if (this.disabledInteractionTimeout)
            clearTimeout(this.disabledInteractionTimeout);
    }

    @ViewChild('pauseModal') pauseModal!: OfPauseModalComponent;
    pauseMultiOFPhase() {
        this.pauseModal.openModal();
    }

    finishMultiOFPhase() {
        this.disableInteraction();
        this.ofService.finishMultiOFPhase().then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al finalizar la fase'
                );
            }
        });
    }

    deselectOFs() {
        this.selectedOFs = [];
    }

    startMultiOFPhase() {
        this.disableInteraction();
        this.ofService
            .startMultiOFPhase(this.selectedOFs, 'embalaje')
            .then(([success, error]) => {
                if (!success) {
                    this.refreshList();
                    return Notiflix.Notify.failure(
                        error || 'Error al iniciar la sesión'
                    );
                }

                this.selectedOFs = [];

                this.enableInteraction();
            });
    }

    @ViewChild('unlinkedSessionModal')
    unlinkedSessionModal!: UnlinkedSessionModalComponent;
    runUnlinkedSession() {
        if (this.imRunningUnlinked) return;

        this.unlinkedSessionModal.openModal();
    }

    stopUnlinkedSession() {
        if (!this.imRunningUnlinked) return;

        this.disableInteraction();
        this.unlinkedSessionService
            .finishUnlinkedSession()
            .then(([success, error]) => {
                if (!success) {
                    this.refreshList();
                    return Notiflix.Notify.failure(
                        error || 'Error al finalizar la sesión'
                    );
                }

                this.enableInteraction();
            });
    }

    onFiltersChange() {
        const sorting_keys = Object.keys(this.sortings);
        let sort_by = sorting_keys.length > 0 ? sorting_keys[0] : false;
        const sort_direction =
            sort_by !== false ? this.sortings[sort_by] : false;

        this.ofItemList_filtered = this.ofItemList
            .filter(i => i.phases.some(p => p.name === 'embalaje' && p.enabled))
            .filter((item: any) =>
                item.linea_composed_id
                    .toLowerCase()
                    .includes(this.seachFilter.toLowerCase())
            )
            .sort((a: any, b: any) => {
                if (sort_by === false) return 0;

                sort_by = sort_by as string;
                let a_val = a[sort_by];
                let b_val = b[sort_by];

                if (typeof a_val === 'string') a_val = a_val.toLowerCase();
                if (typeof b_val === 'string') b_val = b_val.toLowerCase();

                if (a_val > b_val) return sort_direction;
                if (a_val < b_val) return -sort_direction;

                return 0;
            });
    }

    trackByFn(index: number, item: any): number {
        return item.uid;
    }

    resumeOFPhase(of: OF, phase: Phase) {
        /*    this.disableInteraction();
        this.ofService.resumeOFPhase(of, phase).then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(error || 'Error al reanudar la fase');
            }
        });*/
    }

    finishOFPhase(of: OF, phase: Phase) {
        this.disableInteraction();
        this.ofService.finishOFPhase(of, phase).then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al finalizar la fase'
                );
            }
        });
    }

    sortings: any = {};
    sortBy(column: string) {
        if (column in this.sortings) {
            if (this.sortings[column] == 1) this.sortings[column] = -1;
            else this.sortings[column] = 1;
        } else {
            this.sortings = {};
            this.sortings[column] = 1;
        }

        this.onFiltersChange();
    }

    selectedOFs: string[] = [];
    selectToggle(of: OF) {
        if (this.selectedOFs.includes(of.uid))
            this.selectedOFs.splice(this.selectedOFs.indexOf(of.uid), 1);
        else this.selectedOFs.push(of.uid);
    }
}
