import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'localDate',
})
export class LocalDatePipe extends DatePipe implements PipeTransform {
    override transform(
        value: any,
        format?: string,
        timezone?: string,
        locale?: string
    ): any {
        // Comprobar si la fecha es válida
        if (!value) {
            return super.transform(value, format, timezone, locale);
        }
        try {
            // Convierte la fecha/hora de Zulu a hora local
            const date = new Date(value);
            const localDate = date.toLocaleString();

            // if invalid date, return the original value
            if (isNaN(date.getTime())) {
                return super.transform(value, format, timezone, locale);
            }

            // Resort the month and the date
            const dateParts = localDate.split('/');
            // replacet move index 0 to index 1
            dateParts.splice(1, 0, dateParts.splice(0, 1)[0]);
            // join back to string
            const newDate = dateParts.join('/');

            return super.transform(newDate, format, timezone, locale);
        } catch (error) {
            return super.transform(value, format, timezone, locale);
        }
    }
}
