<div class="auth-form-container">
    <div class="auth-form auth-form-login">
        <img src="assets/logos/eremu-auth.png" alt="logo" class="logo" />
        <form id="login-form" (ngSubmit)="doLogin()" ngNativeValidate>
            <h4 class="auth-title">Iniciar Sesión</h4>
            <p class="auth-sub">Ingrese su correo electrónico y contraseña</p>

            <div class="form-label">
                <label for="user">Usuario</label>
            </div>
            <div class="form-field-input">
                <select
                    id="user"
                    name="user"
                    [(ngModel)]="worker_uid"
                    [disabled]="authenticating"
                    required>
                    <option value="" selected disabled>
                        Selecciona un usuario
                    </option>
                    <option
                        *ngFor="let employee of employees"
                        [value]="employee.uid">
                        {{ employee.first_name }} {{ employee.last_name }}
                    </option>
                </select>
            </div>
            <div class="form-label form-label-spaced">
                <label for="password">Contraseña</label>
                <a href="/auth/lost-password" class="forgot-password"
                    >Has olvidado tu contraseña?</a
                >
            </div>
            <div class="form-field-input">
                <input
                    [type]="showPassword ? 'text' : 'password'"
                    id="password"
                    name="password"
                    minlength="3"
                    maxlength="4"
                    placeholder="Contraseña"
                    [(ngModel)]="password"
                    [disabled]="authenticating"
                    required />
                <button
                    type="submit"
                    class="show-password"
                    [class.showing-password]="showPassword"
                    (click)="toggleShowPassword()">
                    <i class="fa-solid fa-eye"></i>
                    <i class="fa-solid fa-eye-slash"></i>
                </button>
            </div>
            <button
                type="submit"
                class="btn-auth btn-auth-login"
                [class.btn-loading]="authenticating"
                [disabled]="authenticating">
                Iniciar Sesión
            </button>
        </form>
        <div class="alert alert-danger mt-2 mb-0" *ngIf="authenticationError">
            <i class="fa-solid fa-exclamation-triangle mr-2"></i>
            <span [innerHtml]="authenticationError"></span>
        </div>
    </div>
</div>
