import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Empleado from 'src/app/models/Empleado';

import AuthService from 'src/app/services/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.component.scss', './login.component.scss'],
})
export class LoginComponent implements OnInit {
    worker_uid = '';
    password = '';

    authenticating = true;
    authenticationError = '';

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    employees: Empleado[] = [];

    ngOnInit(): void {
        this.updateEmployeeList();
    }

    updateEmployeeList() {
        this.authService
            .listEmployees()
            .then(([success, res]) => {
                if (!success) {
                    setTimeout(() => this.updateEmployeeList(), 1000);
                    return;
                }

                this.employees = res as Empleado[];
                this.authenticating = false;
            })
            .catch(err => {
                setTimeout(() => this.updateEmployeeList(), 1000);
            });
    }

    doLogin() {
        this.authenticating = true;
        this.authenticationError = '';
        this.authService
            .authenticate(this.worker_uid, this.password)
            .then(res => {
                this.authenticating = false;
                this.router.navigate(['/ofs']);
            })
            .catch(err => {
                this.authenticating = false;
                this.authenticationError = err;
            });
    }

    showPassword = false;
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
