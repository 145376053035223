import { Component } from '@angular/core';
import AuthService from 'src/app/services/auth.service';

@Component({
    selector: 'app-aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent {
    constructor(private authService: AuthService) {}
}
