import { Component, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import AuthService from 'src/app/services/auth.service';
import MeService from 'src/app/services/me.service';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements AfterViewInit {
    @Input()
    title = '';

    constructor(
        private router: Router,
        private authService: AuthService,
        private meService: MeService
    ) {}

    avatar_public_url!: string;
    full_name!: string;
    ngAfterViewInit() {
        this.meService.getBasic().then(user => {
            if (!user) return;
            this.avatar_public_url = user.avatar_public_url as string;
            this.full_name = `${user.first_name} ${user.last_name}`;
        });
    }

    showingProfileSelector = false;
    toggleProfileSelector() {
        this.showingProfileSelector = !this.showingProfileSelector;
    }

    logout() {
        this.authService
            .logout()
            .then(() => this.router.navigate(['/auth/login']));
    }
}
