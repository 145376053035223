import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './components/auth/login/login.component';
import { LostPasswordComponent } from './components/auth/lost-password/lost-password.component';

import { OFsViewComponent } from './components/main/ofs-view/ofs-view.component';

import { LoaderComponent } from './components/main/shared/loader/loader.component';

import { TopNavComponent } from './components/main/shared/top-nav/top-nav.component';
import { AsideMenuComponent } from './components/main/shared/aside-menu/aside-menu.component';
import { PhaseContainerComponent } from './components/main/shared/phase-container/phase-container.component';
import { FileManagerComponent } from './components/main/shared/file-manager/file-manager.component';

import { OfPauseModalComponent } from './components/main/of-pause-modal/of-pause-modal.component';
import { UnlinkedSessionModalComponent } from './components/main/unlinked-session-modal/unlinked-session-modal.component';
import { EmbalajesViewComponent } from './components/main/embalajes-view/embalajes-view.component';
import { StatsViewComponent } from './components/main/stats-view/stats-view.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { CameraCaptureModalComponent } from './components/main/camera-capture-modal/camera-capture-modal.component';

import { FormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
    declarations: [
        AppComponent,

        LoginComponent,
        LostPasswordComponent,

        AsideMenuComponent,
        TopNavComponent,

        OFsViewComponent,

        LoaderComponent,

        PhaseContainerComponent,
        FileManagerComponent,
        OfPauseModalComponent,

        UnlinkedSessionModalComponent,
        EmbalajesViewComponent,
        StatsViewComponent,

        LocalDatePipe,
        CameraCaptureModalComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgxDocViewerModule,
        WebcamModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
