<div
    class="modal"
    #unlinkedSessionModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="unlinkedSessionModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <div class="modal-header">
                <h2 class="modal-title">Iniciar sesión desvinculada</h2>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button
                            class="btn btn-unlinked-opt"
                            [class.active]="work_code === 'limpieza_y_orden'"
                            (click)="setOpt('limpieza_y_orden')">
                            Limpieza y Orden
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-unlinked-opt"
                            [class.active]="work_code === 'horno'"
                            (click)="setOpt('horno')">
                            Horno
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-unlinked-opt"
                            [class.active]="work_code === 'mantenimiento'"
                            (click)="setOpt('mantenimiento')">
                            Mantenimiento
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-unlinked-opt"
                            [class.active]="work_code === 'descarga'"
                            (click)="setOpt('descarga')">
                            Descarga
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-unlinked-opt"
                            [class.active]="work_code === 'otros'"
                            (click)="setOpt('otros')">
                            Otros
                        </button>
                    </div>
                </div>
                <hr />
                <textarea
                    class="form-control"
                    rows="3"
                    [disabled]="!work_code"
                    [(ngModel)]="work_code_extended"
                    placeholder="Observaciones..."
                    (input)="checkSelectedOpt()"
                    (change)="checkSelectedOpt()"></textarea>
            </div>
            <div class="modal-footer">
                <button
                    class="btn btn-unlinked"
                    (click)="startUnlinkedSession()"
                    [disabled]="!selected_opt_valid || startingUnlinkedSession">
                    Iniciar sesión desvinculada
                </button>
            </div>
        </div>
    </div>
</div>
