import {
    Component,
    EventEmitter,
    AfterViewInit,
    Input,
    Output,
    ElementRef,
    ViewChild,
} from '@angular/core';

import { UnlinkedSessionService } from '../../../services/main/unlinked-session.service';

declare let $: any;
declare let Notiflix: any;

@Component({
    selector: 'app-unlinked-session-modal',
    templateUrl: './unlinked-session-modal.component.html',
    styleUrls: ['./unlinked-session-modal.component.scss'],
})
export class UnlinkedSessionModalComponent implements AfterViewInit {
    showing = false;

    @Input()
    disabledInteraction = false;

    @Output()
    disabledInteractionBind = new EventEmitter<boolean>();

    @ViewChild('unlinkedSessionModal') unlinkedSessionModal!: ElementRef;

    constructor(private unlinkedSessionService: UnlinkedSessionService) {}

    unlinkedSessionModalQ: any;
    ngAfterViewInit() {
        this.unlinkedSessionModalQ = $(this.unlinkedSessionModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    openModal() {
        this.unlinkedSessionModalQ.modal('show');

        this.work_code = '';
        this.work_code_extended = '';
    }

    work_code!: string;
    work_code_extended!: string;
    setOpt(opt: string) {
        this.work_code = opt;

        this.checkSelectedOpt();
    }

    selected_opt_valid = false;
    isSelectedOptValid() {
        if (!this.work_code) return false;
        if (this.work_code == 'otros' && !this.work_code_extended) return false;

        return true;
    }

    checkSelectedOpt() {
        this.selected_opt_valid = this.isSelectedOptValid();
    }

    startingUnlinkedSession = false;
    startUnlinkedSession() {
        if (!this.selected_opt_valid) return;
        this.disabledInteractionBind.emit(true);

        this.startingUnlinkedSession = true;
        this.unlinkedSessionService
            .startUnlinkedSession(this.work_code, this.work_code_extended)
            .then(([success, error]) => {
                this.startingUnlinkedSession = false;
                setTimeout(() => this.disabledInteractionBind.emit(false), 750);
                if (!success)
                    return Notiflix.Notify.failure(
                        error || 'Error al pausar la fase'
                    );
                this.unlinkedSessionModalQ.modal('hide');
            })
            .catch(error => {
                this.startingUnlinkedSession = false;
                setTimeout(() => this.disabledInteractionBind.emit(false), 750);
                Notiflix.Notify.failure(error || 'Error al pausar la fase');
                this.unlinkedSessionModalQ.modal('hide');
            });
    }
}
