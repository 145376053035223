<div class="top-nav">
    <div class="nav-title">
        <h2>{{ title }}</h2>
    </div>
    <div class="nav-center-content">
        <ng-content></ng-content>
    </div>
    <div>
        <div class="profile-selector">
            <div class="profile" (click)="toggleProfileSelector()">
                <span class="name">{{ full_name }}</span>
                <div class="avatar">
                    <img [src]="avatar_public_url" alt="avatar" />
                </div>
            </div>
            <div class="selector-select" *ngIf="showingProfileSelector">
                <!--<button class="profile">
                    <span class="name">Markel Belda Cano</span>
                    <div class="avatar">
                        <img src="assets/images/avatar.png" alt="avatar">
                    </div>
                </button>-->
                <button class="logout" (click)="logout()">
                    <span class="logout-text">Cerrar Sesión</span>
                    <div class="logout-icon">
                        <svg
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.5625 3.9375C7.28848 3.9375 7.875 3.35098 7.875 2.625C7.875 1.89902 7.28848 1.3125 6.5625 1.3125H3.9375C1.76367 1.3125 0 3.07617 0 5.25V15.75C0 17.9238 1.76367 19.6875 3.9375 19.6875H6.5625C7.28848 19.6875 7.875 19.101 7.875 18.375C7.875 17.649 7.28848 17.0625 6.5625 17.0625H3.9375C3.21152 17.0625 2.625 16.476 2.625 15.75V5.25C2.625 4.52402 3.21152 3.9375 3.9375 3.9375H6.5625ZM20.6924 11.2137C20.8893 11.0291 21 10.7707 21 10.5C21 10.2293 20.8893 9.9709 20.6924 9.78633L14.7861 4.2082C14.499 3.9375 14.0807 3.86367 13.7197 4.01953C13.3588 4.17539 13.125 4.53223 13.125 4.92188V7.875H7.875C7.14902 7.875 6.5625 8.46152 6.5625 9.1875V11.8125C6.5625 12.5385 7.14902 13.125 7.875 13.125H13.125V16.0781C13.125 16.4719 13.3588 16.8246 13.7197 16.9805C14.0807 17.1363 14.499 17.0625 14.7861 16.7918L20.6924 11.2137Z"
                                fill="#555555" />
                        </svg>
                    </div>
                </button>
                <!--<button class="add-account" disabled>
                    <span class="add-account-text">Añadir Cuetna</span>
                    <div class="add-account-icon">
                        <svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.3 5.25C14.3 8.1498 11.9722 10.5 9.1 10.5C6.22781 10.5 3.9 8.1498 3.9 5.25C3.9 2.3502 6.22781 0 9.1 0C11.9722 0 14.3 2.3502 14.3 5.25ZM0 19.7818C0 15.7418 3.24187 12.4688 7.24344 12.4688H10.9566C14.9581 12.4688 18.2 15.7418 18.2 19.7818C18.2 20.4545 17.6597 21 16.9934 21H1.20656C0.540313 21 0 20.4545 0 19.7818ZM20.475 12.7969V10.1719H17.875C17.3347 10.1719 16.9 9.73301 16.9 9.1875C16.9 8.64199 17.3347 8.20312 17.875 8.20312H20.475V5.57812C20.475 5.03262 20.9097 4.59375 21.45 4.59375C21.9903 4.59375 22.425 5.03262 22.425 5.57812V8.20312H25.025C25.5653 8.20312 26 8.64199 26 9.1875C26 9.73301 25.5653 10.1719 25.025 10.1719H22.425V12.7969C22.425 13.3424 21.9903 13.7812 21.45 13.7812C20.9097 13.7812 20.475 13.3424 20.475 12.7969Z" fill="#555555"/>
                        </svg>
                    </div>
                </button>-->
            </div>
        </div>
    </div>
</div>
