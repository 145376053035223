<div class="file-manager-nav">
    <button
        class="back-button"
        *ngIf="path.length"
        (click)="backFolder(path.length - 2)">
        <i class="fa fa-arrow-left"></i>
    </button>
    <div class="back-path">
        <button class="back-folder-home" (click)="backFolder(-1)">
            <i class="fa fa-home"></i>
        </button>
        <button
            class="back-folder"
            *ngFor="let folder of path; let i = index"
            (click)="backFolder(i)">
            {{ folder }}
        </button>
    </div>
</div>
<div class="file-manager-loading" *ngIf="updatingPath">
    <app-loader></app-loader>
</div>
<div class="w-100" *ngIf="!updatingPath">
    <div class="file-manager" *ngIf="!isPathFile">
        <div
            class="file"
            [class.is-folder]="file.isFolder"
            [attr.data-file-ext]="file.extension"
            (click)="fileClicked(file)"
            *ngFor="let file of files">
            <div class="file-content">
                <div class="file-icon">
                    <svg
                        *ngIf="file.isFolder"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512">
                        <path
                            d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z" />
                    </svg>
                    <svg
                        *ngIf="!file.isFolder"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 384 512">
                        <path
                            d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z" />
                    </svg>
                </div>
                <div class="file-info">
                    <div class="file-title">{{ file.name }}</div>
                    <div class="file-subtitle" *ngIf="file.description?.length">
                        {{ file.description }}
                    </div>
                </div>
            </div>
        </div>
        <div class="file-add" (click)="openCapCaptureModal()">
            <div class="file-content">
                <div class="file-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512">
                        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z" />
                    </svg>
                </div>
                <div class="file-title">Capturar imagen</div>
            </div>
        </div>
    </div>
    <div class="file-preview" *ngIf="isPathFile">
        <div *ngIf="!isImage(pathFile)">
            <ngx-doc-viewer
                [hidden]="isPDFLoaded"
                [url]="getServeFile(pathFile)"
                viewer="google"
                (loaded)="pdfLoaded()"
                style="width: 100%; height: 50vh"></ngx-doc-viewer>
            <div class="file-preview-loading" *ngIf="!isPDFLoaded">
                <app-loader></app-loader>
            </div>
        </div>
        <div *ngIf="isImage(pathFile)">
            <img
                [src]="getServeFile(pathFile)"
                class="w-100 img-preview"
                alt="file-preview" />
            <p *ngIf="pathFile?.description?.length" class="form-control mt-2">
                <strong>Descripción:</strong> {{ pathFile.description ? pathFile.description : '' }}
            </p>
        </div>
    </div>
</div>

<app-camera-capture-modal
    #camCaptureModal
    (imageUploaded)="updateFiles()"
    [article_id]="article_id"
    [path]="path">
</app-camera-capture-modal>
