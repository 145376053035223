<div class="phase-list" [class.state-toggling]="toggleMode">
    <div
        class="phase-circle"
        [class.double-active]="toggleMode && showTitles"
        [attr.data-phase-state]="phase.state"
        [class.phase-selected]="selectedPhaseUID === phase.uid"
        *ngFor="let phase of phaseListShowing"
        (click)="selectPhase(phase)">
        <svg
            [class.phase-disabled]="!phase.enabled"
            [style.height]="height + 'px'"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                class="phase-circle"
                d="M29.9093 15.1934C29.9093 23.0398 23.6019 29.3867 15.8384 29.3867C8.07492 29.3867 1.76758 23.0398 1.76758 15.1934C1.76758 7.34694 8.07492 1 15.8384 1C23.6019 1 29.9093 7.34694 29.9093 15.1934Z"
                stroke="#D9D9D9"
                stroke-width="2" />
            <path
                *ngFor="let index of getPhaseIconPaths(phase)"
                [attr.d]="getPhaseIcon(phase)[index]"
                fill="#A4A4A4" />
        </svg>
        <div class="active-sessions">
            <div
                class="session-avatar"
                *ngFor="let session of phase.active_sessions">
                <div class="avatar">
                    <img [src]="session.user.avatar_public_url" />
                </div>
            </div>
            <div
                class="session-avatar"
                *ngFor="let session of phase.unfinished_ended_sessions">
                <div class="avatar">
                    <img [src]="session.user.avatar_public_url" />
                </div>
            </div>
        </div>
        <div class="floating-bottom">
            <div *ngIf="showTitles" class="title-toggler">
                <span [style.fontSize]="height * 0.175 + 'px'">{{
                    phase.name
                }}</span>
            </div>
            <div *ngIf="toggleMode" class="state-toggler">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        [id]="'phase-toggler-' + phase.uid"
                        (click)="togglePhaseState.emit(phase)"
                        [(ngModel)]="phase.enabled" />
                </div>
            </div>
        </div>
    </div>
</div>
