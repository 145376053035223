import { Injectable } from '@angular/core';
import APIService from '../api.service';
import OF_Phase_Session from 'src/app/models/OF_Phase_Session';

@Injectable({
    providedIn: 'root',
})
export class SeguimientoService {
    constructor(private api: APIService) {}

    getMyJornadaEmpleado(from_date: string, to_date: string) {
        return new Promise((resolve, reject) => {
            this.api
                .get(`/seguimiento/mi-jornada/`, { from_date, to_date })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data);
                });
        });
    }
}

export default SeguimientoService;
