import { Injectable } from '@angular/core';
import APIService from '../api.service';
import UnlinkedSession from 'src/app/models/UnlinkedSession';

@Injectable({
    providedIn: 'root',
})
export class UnlinkedSessionService {
    constructor(private api: APIService) {}

    startUnlinkedSession(
        work_code: string,
        work_code_extended: string
    ): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/unlinked_session/start/`, {
                    work_code,
                    work_code_extended,
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    finishUnlinkedSession(): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/unlinked_session/finish/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[UnlinkedSessionService]', ...args);
    }
}
