import {
    ViewChild,
    Component,
    OnDestroy,
    AfterContentInit,
} from '@angular/core';
import OF from 'src/app/models/OF';
import Phase from 'src/app/models/OF_Phase';
import OFService from 'src/app/services/main/of.service';
import MeService from 'src/app/services/me.service';
import WebsocketService from 'src/app/services/websocket.service';
import { OfPauseModalComponent } from '../of-pause-modal/of-pause-modal.component';
import { UnlinkedSessionModalComponent } from '../unlinked-session-modal/unlinked-session-modal.component';
import UnlinkedSession from 'src/app/models/UnlinkedSession';
import { UnlinkedSessionService } from 'src/app/services/main/unlinked-session.service';
import Work_Pause from 'src/app/models/Work_Pause';
import { CameraCaptureModalComponent } from '../camera-capture-modal/camera-capture-modal.component';

declare let Notiflix: any;

@Component({
    selector: 'app-ofs-view',
    templateUrl: './ofs-view.component.html',
    styleUrls: ['./ofs-view.component.scss'],
})
export class OFsViewComponent implements OnDestroy, AfterContentInit {
    constructor(
        private ofService: OFService,
        private meService: MeService,
        private wsService: WebsocketService,
        private unlinkedSessionService: UnlinkedSessionService
    ) {}

    readyListener: any;

    refreshInterval: any;
    unbindWS0: any;
    unbindWS: any;
    ngAfterContentInit() {
        this.meService.addEventListener(
            'ready',
            (this.readyListener = async () => {
                this.onOfListUpdated();
            })
        );

        this.sortBy('plazo');

        this.unbindWS0 = this.wsService.on(
            'global:resync',
            async (data: any | null) => {
                if (!data || !data.ofs) return;

                this.active_pauses = data.active_pauses as Work_Pause[];
                this.ofItemList = data.ofs as OF[];
                this.unlinkedSessionList = data.ofs as UnlinkedSession[];
                this.onFiltersChange();
                this.onOfListUpdated();
            }
        );

        this.unbindWS = this.wsService.on(
            'global:updated,of:created',
            async (data: any | null) => {
                if (!data || !data.ofs) {
                    await this.refreshList();
                    //this.enableInteraction();
                    return;
                }

                this.active_pauses = data.active_pauses as Work_Pause[];
                this.ofItemList = data.ofs as OF[];
                this.unlinkedSessionList =
                    data.unlinked_sessions as UnlinkedSession[];
                this.onFiltersChange();
                this.onOfListUpdated();
                //this.enableInteraction();
            }
        );
        this.wsService.sendSocketMessage('global:resync');
    }

    ngOnDestroy() {
        if (this.unbindWS0) this.unbindWS0();
        if (this.unbindWS) this.unbindWS();

        if (this.readyListener)
            this.meService.removeEventListener('ready', this.readyListener);
    }

    imRunningOF = false;
    imPausing = true;
    imRunningEmbalaje = false;
    imRunningUnlinked = false;

    seachFilter = '';
    active_pauses: Work_Pause[] = [];
    ofItemList: OF[] = [];
    ofItemList_filtered: OF[] = [];

    unlinkedSessionList: UnlinkedSession[] = [];

    refreshList() {
        this.wsService.sendSocketMessage('global:resync');
    }

    expandedOF_UID!: string;
    expandToggle(of: OF) {
        if (this.expandedOF_UID === of.uid) this.expandedOF_UID = '';
        else this.expandedOF_UID = of.uid;

        this.expandedOFPhase = null;

        this.ofTab = 'runtime';
    }

    state_titles: any = {
        not_started: 'Sin iniciar',
        in_progress: 'En ejecución',
        paused: 'En pausa',
        completed: 'Completada',
    };

    onOfListUpdated() {
        const expandedOf = this.ofItemList.find(
            of => of.uid === this.expandedOF_UID
        );
        if (!expandedOf) {
            this.expandedOF_UID = '';
            this.expandedOFPhase = null;
        } else {
            if (this.expandedOFPhase) {
                this.expandedOFPhase =
                    expandedOf.phases.find(
                        p => p.uid === this.expandedOFPhase?.uid
                    ) || null;
                if (this.expandedOFPhase)
                    this.checkLocalStates(expandedOf, this.expandedOFPhase);
            }
        }

        this.imRunningOF = this.ofItemList.some(of =>
            of.phases.some(phase =>
                phase.active_sessions.some(
                    s =>
                        !s.is_paused &&
                        s.user.uid === this.meService.cachedBasic?.uid
                )
            )
        );

        this.imPausing = this.active_pauses.some(
            pause => pause.user?.uid === this.meService.cachedBasic?.uid
        );

        this.imRunningEmbalaje = this.ofItemList.some(of =>
            of.phases.some(
                phase =>
                    phase.name === 'embalaje' &&
                    phase.active_sessions.some(
                        s =>
                            !s.is_paused &&
                            s.user.uid === this.meService.cachedBasic?.uid
                    )
            )
        );

        this.imRunningUnlinked = this.unlinkedSessionList.some(
            session => session.user.uid === this.meService.cachedBasic?.uid
        );
    }

    selectedPhaseUID!: string;

    expandedOFPhase: Phase | null = null;
    expandedOFPhaseLocalState!: string;
    extendedOfImPausing = false;
    phaseSelected(of: OF, phase: Phase) {
        if (of.uid !== this.expandedOF_UID) return;
        this.selectedPhaseUID = phase.uid;
        this.expandedOFPhase = phase;
        this.checkLocalStates(of, phase);
    }

    checkLocalStates(of: OF, phase: Phase) {
        if (!this.expandedOFPhase) return;

        const imRunning = phase.active_sessions.some(
            s => s.user.uid === this.meService.cachedBasic?.uid
        );
        const imPausing = phase.unfinished_ended_sessions?.some(
            s => s.user.uid === this.meService.cachedBasic?.uid
        );
        const iFinished =
            !imRunning &&
            !imPausing &&
            phase.finished_sessions.some(
                s => s.user.uid === this.meService.cachedBasic?.uid
            );

        let state = this.expandedOFPhase.state;
        switch (state) {
            case 'in_progress':
                if (iFinished) {
                    state = 'completed';
                    break;
                }

                if (imPausing) {
                    state = 'paused';
                    break;
                }

                if (!imRunning) {
                    state = 'not_started';
                    break;
                }

                break;
            case 'paused':
                if (imPausing) {
                    state = 'paused';
                    break;
                }

                if (iFinished) {
                    state = 'completed';
                    break;
                }

                if (imRunning) {
                    state = 'in_progress';
                    break;
                }

                state = 'not_started';

                break;
        }

        this.expandedOFPhaseLocalState = state;
    }

    phaseDeselected(of: OF) {
        if (of.uid !== this.expandedOF_UID) return;
        this.expandedOFPhase = null;
    }

    disabledInteraction = false;
    disabledInteractionTimeout: any;
    disableInteraction() {
        this.disabledInteraction = true;
        this.disabledInteractionTimeout = setTimeout(
            () => (this.disabledInteraction = false),
            4000
        );
    }

    enableInteraction() {
        this.disabledInteraction = false;
        if (this.disabledInteractionTimeout)
            clearTimeout(this.disabledInteractionTimeout);
    }

    startOFPhase(of: OF, phase: Phase) {
        this.disableInteraction();
        this.ofService.startOFPhase(of, phase).then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al iniciar la fase'
                );
            }
        });
    }

    @ViewChild('pauseModal') pauseModal!: OfPauseModalComponent;
    pauseWorkSession() {
        this.pauseModal.openModal();
    }

    finishMultiOFPhase() {
        this.disableInteraction();
        this.ofService.finishMultiOFPhase().then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al finalizar la fase'
                );
            }
        });
    }

    @ViewChild('unlinkedSessionModal')
    unlinkedSessionModal!: UnlinkedSessionModalComponent;
    runUnlinkedSession() {
        if (this.imRunningUnlinked) return;

        this.unlinkedSessionModal.openModal();
    }

    stopUnlinkedSession() {
        if (!this.imRunningUnlinked) return;

        this.disableInteraction();
        this.unlinkedSessionService
            .finishUnlinkedSession()
            .then(([success, error]) => {
                if (!success) {
                    this.refreshList();
                    return Notiflix.Notify.failure(
                        error || 'Error al finalizar la sesión'
                    );
                }

                this.enableInteraction();
            });
    }

    onFiltersChange() {
        const sorting_keys = Object.keys(this.sortings);
        let sort_by = sorting_keys.length > 0 ? sorting_keys[0] : false;
        const sort_direction =
            sort_by !== false ? this.sortings[sort_by] : false;

        this.ofItemList_filtered = this.ofItemList
            .filter((item: any) =>
                item.linea_composed_id
                    .toLowerCase()
                    .includes(this.seachFilter.toLowerCase())
            )
            .sort((a: any, b: any) => {
                if (sort_by === false) return 0;

                sort_by = sort_by as string;
                let a_val = a[sort_by];
                let b_val = b[sort_by];

                if (typeof a_val === 'string') a_val = a_val.toLowerCase();
                if (typeof b_val === 'string') b_val = b_val.toLowerCase();

                if (a_val > b_val) return sort_direction;
                if (a_val < b_val) return -sort_direction;

                return 0;
            });
    }

    trackByFn(index: number, item: any): number {
        return item.uid;
    }

    resumeOFPhase(of: OF, phase: Phase) {
        this.disableInteraction();
        this.ofService.startOFPhase(of, phase).then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al reanudar la fase'
                );
            }
        });
    }

    finishOFPhase(of: OF, phase: Phase) {
        this.disableInteraction();
        this.ofService.finishOFPhase(of, phase).then(([success, error]) => {
            if (!success) {
                this.refreshList();
                return Notiflix.Notify.failure(
                    error || 'Error al finalizar la fase'
                );
            }
        });
    }

    sortings: any = {};
    sortBy(column: string) {
        if (column in this.sortings) {
            if (this.sortings[column] == 1) this.sortings[column] = -1;
            else this.sortings[column] = 1;
        } else {
            this.sortings = {};
            this.sortings[column] = 1;
        }

        this.onFiltersChange();
    }

    ofTab = 'runtime';
    setOfTab(tab: string) {
        this.ofTab = tab;
    }
}
