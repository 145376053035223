<div class="main-loader" *ngIf="authService.initializing">
    <div class="loader">
        <i class="fas fa-circle-notch fa-spin"></i>
    </div>
</div>
<div
    class="main-app-container"
    *ngIf="!authService.initializing"
    [class.wrapper]="authService.authed">
    <app-aside-menu *ngIf="authService.authed"></app-aside-menu>

    <div [class.content-wrapper]="authService.authed">
        <router-outlet></router-outlet>
    </div>
</div>
