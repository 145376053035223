<aside>
    <div class="aside-top">
        <div class="eremu-logo">
            <img
                src="assets/logos/eremu-logo.png"
                alt="Eremu Logo"
                class="logo" />
        </div>
        <a routerLink="/ofs" routerLinkActive="active" class="nav-btn">
            <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 3H20.5C20.775 3 21 3.225 21 3.5V6H11V3.5C11 3.225 11.225 3 11.5 3ZM8 3.5V6H4C1.79375 6 0 7.79375 0 10V16H12H20H32V10C32 7.79375 30.2062 6 28 6H24V3.5C24 1.56875 22.4312 0 20.5 0H11.5C9.56875 0 8 1.56875 8 3.5ZM32 18H20V20C20 21.1063 19.1063 22 18 22H14C12.8938 22 12 21.1063 12 20V18H0V26C0 28.2062 1.79375 30 4 30H28C30.2062 30 32 28.2062 32 26V18Z"
                    fill="#DDE2FF" />
            </svg>
        </a>
        <a routerLink="/embalajes" routerLinkActive="active" class="nav-btn">
            <svg
                viewBox="5 5 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.73721 9.63195C7.82491 9.45094 8.01784 9.34708 8.2137 9.37379L15.3697 10.2818L22.5258 9.37379C22.7216 9.35005 22.9146 9.45391 23.0023 9.63195L24.2213 12.1068C24.4844 12.638 24.2037 13.2819 23.6425 13.4451L18.8659 14.8309C18.4596 14.9496 18.024 14.7746 17.8077 14.4066L15.3697 10.2818L12.9318 14.4066C12.7155 14.7746 12.2799 14.9496 11.8736 14.8309L7.09995 13.4451C6.53577 13.2819 6.25806 12.638 6.52115 12.1068L7.73721 9.63195ZM15.4019 12.181L17.0067 14.8933C17.4423 15.6292 18.3105 15.9793 19.1261 15.7419L22.8532 14.6618V19.6174C22.8532 20.2703 22.4147 20.84 21.7891 21.0003L15.8228 22.5137C15.5247 22.5908 15.2119 22.5908 14.9166 22.5137L8.95035 21.0003C8.32478 20.8371 7.8863 20.2673 7.8863 19.6145V14.6588L11.6163 15.7419C12.429 15.9793 13.3001 15.6292 13.7357 14.8933L15.3376 12.181H15.4019V12.181Z"></path>
            </svg>
        </a>
        <a routerLink="/stats" routerLinkActive="active" class="nav-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                    d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
            </svg>
        </a>
        <!-- <a disabled class="nav-btn">
            <svg viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.45179 0.283121C3.9137 -0.13602 3.14905 -0.085043 2.66194 0.396402L0.396319 2.66203C-0.0851259 3.14347 -0.136102 3.90812 0.277374 4.45187L4.80862 10.3425C5.06351 10.6767 5.46566 10.8749 5.8848 10.8749H8.94905L15.1229 17.0487C14.2903 18.6913 14.5565 20.753 15.9328 22.1237L22.2766 28.4675C22.9846 29.1755 24.1344 29.1755 24.8424 28.4675L28.4674 24.8425C29.1754 24.1345 29.1754 22.9847 28.4674 22.2767L22.1237 15.9329C20.753 14.5622 18.6912 14.2903 17.0487 15.123L10.8748 8.94914V5.89054C10.8748 5.46574 10.6766 5.06925 10.3424 4.81437L4.45179 0.283121ZM1.12698 22.4353C0.407648 23.1546 -0.000164942 24.1345 -0.000164942 25.154C-0.000164942 27.278 1.72171 28.9999 3.84573 28.9999C4.86526 28.9999 5.84515 28.5921 6.56448 27.8728L13.2367 21.2005C12.795 20.0167 12.727 18.731 13.0328 17.5132L9.53812 14.0185L1.12698 22.4353ZM28.9998 8.15617C28.9998 7.56144 28.9375 6.98371 28.8186 6.42863C28.6826 5.79425 27.9067 5.63 27.4479 6.08879L23.8285 9.70812C23.6586 9.87804 23.4264 9.97433 23.1885 9.97433H19.9373C19.4389 9.97433 19.0311 9.56652 19.0311 9.06808V5.81125C19.0311 5.57336 19.1274 5.34113 19.2973 5.17121L22.9166 1.55187C23.3754 1.09308 23.2112 0.317105 22.5768 0.181168C22.016 0.0622226 21.4383 -8.20546e-05 20.8436 -8.20546e-05C16.3407 -8.20546e-05 12.6873 3.65324 12.6873 8.15617V8.20148L17.5188 13.0329C19.5578 12.5175 21.8121 13.0612 23.4094 14.6585L24.2987 15.5478C27.0741 14.245 28.9998 11.4243 28.9998 8.15617ZM5.89046 24.4687C5.89046 25.222 5.28441 25.828 4.53109 25.828C3.77776 25.828 3.17171 25.222 3.17171 24.4687C3.17171 23.7153 3.77776 23.1093 4.53109 23.1093C5.28441 23.1093 5.89046 23.7153 5.89046 24.4687Z" fill="#90929E" />
            </svg>
        </a> -->
    </div>
    <div class="aside-bottom">
        <a disabled class="nav-btn">
            <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.4"
                    d="M30.4625 19.7312L27.8 18.1937C28.0687 16.7437 28.0687 15.2562 27.8 13.8062L30.4625 12.2687C30.7687 12.0937 30.9062 11.7312 30.8062 11.3937C30.1125 9.16866 28.9312 7.15616 27.3875 5.48116C27.15 5.22491 26.7625 5.16241 26.4625 5.33741L23.8 6.87491C22.6812 5.91241 21.3937 5.16866 20 4.68116V1.61241C20 1.26241 19.7563 0.956162 19.4125 0.881162C17.1188 0.368662 14.7688 0.393662 12.5875 0.881162C12.2438 0.956162 12 1.26241 12 1.61241V4.68741C10.6125 5.18116 9.32501 5.92491 8.20001 6.88116L5.54376 5.34366C5.23751 5.16866 4.85626 5.22491 4.61876 5.48741C3.07501 7.15616 1.89376 9.16866 1.20001 11.3999C1.09376 11.7374 1.23751 12.0999 1.54376 12.2749L4.20626 13.8124C3.93751 15.2624 3.93751 16.7499 4.20626 18.1999L1.54376 19.7374C1.23751 19.9124 1.10001 20.2749 1.20001 20.6124C1.89376 22.8374 3.07501 24.8499 4.61876 26.5249C4.85626 26.7812 5.24376 26.8437 5.54376 26.6687L8.20626 25.1312C9.32501 26.0937 10.6125 26.8374 12.0063 27.3249V30.3999C12.0063 30.7499 12.25 31.0562 12.5938 31.1312C14.8875 31.6437 17.2375 31.6187 19.4188 31.1312C19.7625 31.0562 20.0063 30.7499 20.0063 30.3999V27.3249C21.3937 26.8312 22.6812 26.0874 23.8062 25.1312L26.4687 26.6687C26.775 26.8437 27.1562 26.7874 27.3937 26.5249C28.9375 24.8562 30.1187 22.8437 30.8125 20.6124C30.9062 20.2687 30.7687 19.9062 30.4625 19.7312ZM16 20.9999C13.2438 20.9999 11 18.7562 11 15.9999C11 13.2437 13.2438 10.9999 16 10.9999C18.7563 10.9999 21 13.2437 21 15.9999C21 18.7562 18.7563 20.9999 16 20.9999Z"
                    fill="#9FA2B4" />
            </svg>
        </a>
        <!--<a (click)="logout()"class="nav-btn">
            <div class="logout-container">
                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.7 17.4125C30.3944 16.6313 30.3944 15.3625 29.7 14.5813L22.5889 6.58125C21.8944 5.8 20.7667 5.8 20.0722 6.58125C19.3778 7.3625 19.3778 8.63125 20.0722 9.4125L24.15 14H12.4444C11.4611 14 10.6667 14.8938 10.6667 16C10.6667 17.1062 11.4611 18 12.4444 18H24.15L20.0722 22.5875C19.3778 23.3687 19.3778 24.6375 20.0722 25.4188C20.7667 26.2 21.8944 26.2 22.5889 25.4188L29.7 17.4188V17.4125ZM10.6667 6C11.65 6 12.4444 5.10625 12.4444 4C12.4444 2.89375 11.65 2 10.6667 2H7.1111C4.16666 2 1.77777 4.6875 1.77777 8V24C1.77777 27.3125 4.16666 30 7.1111 30H10.6667C11.65 30 12.4444 29.1063 12.4444 28C12.4444 26.8937 11.65 26 10.6667 26H7.1111C6.12777 26 5.33333 25.1063 5.33333 24V8C5.33333 6.89375 6.12777 6 7.1111 6H10.6667Z" fill="#9FA2B4"/>
                </svg>
            </div>
        </a>-->
    </div>
</aside>
