import { Injectable } from '@angular/core';
import { MS } from 'src/consts';

import { JWT } from '../../utils';
import Empleado from '../models/Empleado';
import { APIService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends EventTarget {
    constructor(private api: APIService) {
        super();
    }

    ping(): Promise<boolean> {
        return new Promise(resolve => {
            this.api
                .get('/ping/')
                .then((res: any) => resolve(res.success))
                .catch((err: any) => resolve(false));
        });
    }

    async ensureConnetion() {
        let connected = false;
        while (!connected) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            connected = await this.ping();
        }

        return true;
    }

    initializing = true;
    authed = true;
    accessExpireTime = 0;
    refreshExpireTime = 0;
    accessRefreshTimeout: any = null;
    async sessionCheck() {
        this.log('Initializing auth service');
        let authed = false;
        const tokens = this.getLocalTokens();
        if (!tokens) {
            this.setAuthed(false);
            return false;
        }

        await this.ensureConnetion();
        const { access, refresh } = tokens;
        if (JWT.isExpired(access)) {
            this.log('Access token expired');
            if (JWT.isExpired(refresh)) {
                this.log('Refresh token expired');
                this.clearLocalTokens();
            } else {
                this.log('Refresh token not expired, refreshing access token');
                authed = await this.refreshTokens();
            }
        } else {
            this.log('Access token not expired, you are logged in');
            authed = true;
            this.accessExpireTime = JWT.getExpireTime(access);
            this.refreshExpireTime = JWT.getExpireTime(refresh);

            this.setActiveAuthToken(access);

            this.prepareScheduleRefresh();
        }

        this.setAuthed(authed);
        this.log('Auth service initialized');

        return true;
    }

    triggerEvent(event: any, data: any = null) {
        const eventObject = new CustomEvent(event, { detail: data });
        this.dispatchEvent(eventObject);
    }

    setAuthed(authed: boolean) {
        if (authed === this.authed && !this.initializing) return;

        this.authed = authed;

        if (authed) {
            this.triggerEvent('authed');
        } else {
            this.triggerEvent('unauthed');
        }
        this.initializing = false;
    }

    refreshTokens(): Promise<boolean> {
        return new Promise(resolve => {
            const tokens = this.getLocalTokens();
            if (!tokens) return resolve(false);

            const { refresh } = tokens;
            this.api
                .post('/auth/token/refresh/', { refresh }, false)
                .then((res: any) => {
                    const { access, refresh } = res;
                    this.saveLocalTokens(access, refresh);
                    this.sessionCheck().then(() => resolve(true));
                    this.triggerEvent('refreshed');
                })
                .catch((err: any) => {
                    this.log('Error refreshing access token', err);
                    this.clearLocalTokens();
                    resolve(false);
                });
        });
    }

    refreshPreventionTime: number = MS.inHour * 4;
    prepareScheduleRefresh() {
        const nowMinusMargin = Date.now() + this.refreshPreventionTime;
        const accessTime = this.accessExpireTime - nowMinusMargin;
        const refreshTime = this.refreshExpireTime - nowMinusMargin;

        let timeout = Math.min(accessTime, refreshTime);
        if (timeout < 0) timeout = 0;
        this.scheduleRefresh(timeout);
    }

    scheduleRefresh(timeout: number) {
        if (timeout < 0) timeout = 0;
        this.log('Scheduling refresh in', timeout / 1000 / 60, 'minutes');
        if (this.accessRefreshTimeout) clearTimeout(this.accessRefreshTimeout);
        this.accessRefreshTimeout = setTimeout(() => {
            this.refreshTokens();
        }, timeout);
    }

    setActiveAuthToken(token: string) {
        localStorage.setItem('active_auth_token', token);
    }

    getLocalTokens() {
        const tokens = localStorage.getItem('tokens');
        if (!tokens) return;

        return JSON.parse(tokens);
    }

    getAccessToken() {
        const tokens = this.getLocalTokens();
        if (!tokens) return;

        return tokens.access;
    }

    getRefreshToken() {
        const tokens = this.getLocalTokens();
        if (!tokens) return;

        return tokens.refresh;
    }

    saveLocalTokens(access: string, refresh: string) {
        localStorage.setItem('tokens', JSON.stringify({ access, refresh }));
    }

    clearLocalTokens() {
        this.log('Clearing local tokens');
        localStorage.removeItem('tokens');
    }

    authenticate(worker_uid: string, password: string) {
        return new Promise((resolve, reject) => {
            this.api
                .post('/auth/worker_login/', { worker_uid, password }, false)
                .then((res: any) => {
                    const { access, refresh } = res;
                    this.saveLocalTokens(access, refresh);
                    this.sessionCheck().then(() => resolve(true));
                })
                .catch((err: any) => {
                    let error = 'Error al iniciar sesión';
                    if (err.status === 401)
                        error = 'Usuario o contraseña incorrectos';
                    else if (err.status === 429) {
                        const seconds = err.error?.detail?.split(' ');
                        error =
                            '<strong>Demasiados intentos</strong><br>Espera <strong>' +
                            seconds[seconds.length - 2] +
                            ' segundos</strong> antes de volver a intentarlo';
                    }
                    reject(error);
                });
        });
    }

    listEmployees(): Promise<[boolean, Empleado[] | string | null]> {
        return new Promise(resolve => {
            this.api
                .get(`/auth/employee/list/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, null]);

                    const { data } = res;
                    resolve([true, data as Empleado[]]);
                })
                .catch((error: any) => {
                    this.log('Error while updating user', error);
                    return resolve([false, null]);
                });
        });
    }

    logout() {
        return new Promise(async resolve => {
            const tokens = this.getLocalTokens();
            setTimeout(() => {
                if (!tokens) return;
                this.api.post(
                    '/auth/token/blacklist/',
                    { refresh: tokens.refresh },
                    false
                );
            });
            // We don't care if the request fails, we just want to clear the tokens
            this.clearLocalTokens();
            await this.sessionCheck();
            resolve(true);
        });
    }

    log(...args: any[]) {
        console.debug('[AuthService]', ...args);
    }
}

export default AuthService;
