import { Injectable } from '@angular/core';
import OF from 'src/app/models/OF';
import OF_Phase from 'src/app/models/OF_Phase';

import APIService from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class OFService {
    constructor(private api: APIService) {}

    startOFPhase(of: OF, phase: OF_Phase): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/of/phase/${phase.uid}/start/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    finishOFPhase(of: OF, phase: OF_Phase): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/of/phase/${phase.uid}/finish/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    startMultiOFPhase(
        ofs: string[],
        phase_name: string
    ): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/multi_ofs/phase/start/`, {
                    ofs: ofs,
                    phase_name,
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    finishMultiOFPhase(): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/multi_ofs/phase/finish/`)
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    pauseWorkSession(
        reason_code: string,
        reason_extended: string
    ): Promise<[boolean, string | false]> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/pause/`, { reason_code, reason_extended })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve([false, res.error]);

                    const { data } = res;
                    return resolve([true, false]);
                })
                .catch(err => {
                    this.log(err);
                    return resolve([false, false]);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[OFService]', ...args);
    }
}

export default OFService;
