<app-top-nav title="Embalajes">
    <div class="col-12 col-lg">
        <span
            >{{ selectedOFs.length }}/{{ ofItemList.length }} OFs
            seleccionadas</span
        >
    </div>
    <div class="col-12 col-lg-3">
        <input
            type="text"
            class="form-control"
            placeholder="Buscar por ID..."
            [(ngModel)]="seachFilter"
            (input)="onFiltersChange()"
            (change)="onFiltersChange()" />
    </div>
    <div class="nav-running-container" *ngIf="imRunningEmbalaje">
        <button
            class="btn btn-state btn-pause ml-1"
            (click)="finishMultiOFPhase()"
            [disabled]="disabledInteraction">
            Finalizar Embalaje
        </button>
    </div>
    <div class="nav-running-container" *ngIf="!imPausing">
        <button
            class="btn btn-state btn-pause ml-1"
            (click)="pauseMultiOFPhase()"
            [disabled]="disabledInteraction">
            Parada
        </button>
    </div>
    <div class="nav-running-container" *ngIf="!imRunningUnlinked">
        <button
            class="btn btn-state btn-finish ml-1"
            (click)="runUnlinkedSession()"
            [disabled]="disabledInteraction">
            Iniciar Sesión desvinculada
        </button>
    </div>
    <div class="nav-running-container" *ngIf="imRunningUnlinked">
        <button
            class="btn btn-danger btn-state ml-1"
            (click)="stopUnlinkedSession()"
            [disabled]="disabledInteraction">
            Finalizar Sesión desvinculada
        </button>
    </div>
</app-top-nav>

<div class="custom-table">
    <div class="table-body">
        <div class="table-row table-cols pt-4">
            <div class="cell">
                <span
                    class="sorting"
                    [class.sorting_desc]="sortings['linea_composed_id'] === -1"
                    [class.sorting_asc]="sortings['linea_composed_id'] === 1"
                    (click)="sortBy('linea_composed_id')"
                    >Orden</span
                >
            </div>
            <div class="cell">
                <span
                    class="sorting"
                    [class.sorting_desc]="sortings['plazo'] === -1"
                    [class.sorting_asc]="sortings['plazo'] === 1"
                    (click)="sortBy('plazo')"
                    >Fecha Límite</span
                >
            </div>
            <div class="cell"></div>
            <div class="cell"></div>
        </div>
        <div
            class="table-row"
            *ngFor="let _of of ofItemList_filtered; let i = index; trackBy: trackByFn"
            (click)="selectToggle(_of)">
            <div class="cell">
                <div class="of-data">
                    <div class="of-composed">
                        <strong>{{ _of.linea_composed_id }}</strong>
                        <div class="uds-span">{{ _of.ctd }}uds</div>
                        <div class="client-span">{{ _of.client.name }}</div>
                    </div>
                    <div class="descr-art">{{ _of.descrip_articulo }}</div>
                </div>
            </div>
            <div class="cell date-container">
                <span class="date">{{ _of.plazo | date: 'd MMM, y' }}</span>
                <!--<br>
                <span class="hour">{{_of.plazo | date:'HH:mm'}}</span>-->
            </div>
            <div class="cell">
                <app-phase-container
                    [height]="40"
                    [phaseList]="_of.phases"
                    [embalaje]="true"></app-phase-container>
            </div>
            <div class="cell select-container">
                <div class="select">
                    <div class="state-toggler">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                [checked]="selectedOFs.includes(_of.uid)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="floating-actions">
    <button
        class="btn btn-danger btn-state"
        (click)="deselectOFs()"
        *ngIf="selectedOFs.length"
        [disabled]="disabledInteraction">
        Deseleccionar todas
    </button>
    <button
        class="btn btn-success btn-state ml-1"
        (click)="startMultiOFPhase()"
        *ngIf="selectedOFs.length && !imRunningEmbalaje"
        [disabled]="disabledInteraction">
        Iniciar sesión de embalaje
    </button>
</div>

<app-of-pause-modal
    #pauseModal
    [multiOf]="true"
    [disabledInteraction]="disabledInteraction"
    (disabledInteractionBind)="disabledInteraction = $event">
</app-of-pause-modal>

<app-unlinked-session-modal
    #unlinkedSessionModal
    [disabledInteraction]="disabledInteraction"
    (disabledInteractionBind)="disabledInteraction = $event">
</app-unlinked-session-modal>
