import { Injectable } from '@angular/core';
import File from 'src/app/models/File';

import APIService from '../api.service';

type ArticleFilesResponse = {
    files?: File[];
    file: File;
    isFile?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class FileManagerService {
    constructor(private api: APIService) {}

    getArticleFiles(
        article_id: string,
        path: string[] = []
    ): Promise<ArticleFilesResponse | null> {
        return new Promise(resolve => {
            this.api
                .get(`/workers/articles/files/`, {
                    article_id,
                    path: path.join('<>'),
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    getServePath() {
        return this.api.base_url + '/workers/articles/files/serve/';
    }

    uploadImageCapture(
        article_id: string,
        path: string[] = [],
        image_title: string,
        image_description: string,
        image_data: string
    ): Promise<ArticleFilesResponse | null> {
        return new Promise(resolve => {
            this.api
                .post(`/workers/articles/files/upload/`, {
                    article_id,
                    path: path.join('<>'),
                    image_title,
                    image_description,
                    image_data
                })
                .then((res: any) => {
                    const { success } = res;
                    if (!success) return resolve(null);

                    const { data } = res;
                    return resolve(data);
                })
                .catch(err => {
                    this.log(err);
                    return resolve(null);
                });
        });
    }

    log(...args: any[]) {
        console.debug('[FileManagerService]', ...args);
    }
}

export default FileManagerService;
