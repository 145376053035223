import {
    Component,
    ElementRef,
    AfterViewInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import OFService from 'src/app/services/main/of.service';

declare let $: any;
declare let Notiflix: any;

@Component({
    selector: 'app-of-pause-modal',
    templateUrl: './of-pause-modal.component.html',
    styleUrls: ['./of-pause-modal.component.scss'],
})
export class OfPauseModalComponent implements AfterViewInit {
    showing = false;

    @Input()
    multiOf = false;

    @Input()
    disabledInteraction = false;

    @Output()
    disabledInteractionBind = new EventEmitter<boolean>();

    @ViewChild('ofPauseModal') ofPauseModal!: ElementRef;

    constructor(private ofService: OFService) {}

    ofPauseModalQ: any;
    ngAfterViewInit() {
        this.ofPauseModalQ = $(this.ofPauseModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));
    }

    openModal() {
        this.ofPauseModalQ.modal('show');

        this.selected_opt = '';
        this.selected_opt_extra = '';
    }

    selected_opt!: string;
    selected_opt_extra!: string;
    setOpt(opt: string) {
        this.selected_opt = opt;
    }

    pausingSession = false;
    pauseWorkSession() {
        if (!this.selected_opt) return;
        this.disabledInteractionBind.emit(true);

        this.pausingSession = true;
        this.ofService
            .pauseWorkSession(this.selected_opt, this.selected_opt_extra)
            .then(([success, error]) => {
                this.pausingSession = false;
                setTimeout(() => this.disabledInteractionBind.emit(false), 750);
                if (!success)
                    return Notiflix.Notify.failure(
                        error || 'Error al pausar la fase'
                    );
                this.ofPauseModalQ.modal('hide');
            })
            .catch(err => {
                this.pausingSession = false;
                setTimeout(() => this.disabledInteractionBind.emit(false), 750);
                Notiflix.Notify.failure('Error al pausar la fase');
            });
    }
}
