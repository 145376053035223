<app-top-nav title="Resumen Jornada"></app-top-nav>

<div class="mt-3">
    <div class="card">
        <div class="card-body">
            Tiempo total trabajado hoy:
            <strong>{{ prettyTimeSring(jornada.total_time) }}</strong>
        </div>
    </div>
</div>
<div class="card mt-3">
    <div class="card-body">
        <div class="custom-table table-no-border" *ngIf="jornada">
            <div class="table-body">
                <div class="table-row table-cols">
                    <div class="cell">
                        <span>OF</span>
                    </div>
                    <div class="cell">
                        <span>UDs/Cliente</span>
                    </div>
                    <div class="cell">
                        <span>Fase</span>
                    </div>
                    <div class="cell">
                        <span>Inicio</span>
                    </div>
                    <div class="cell">
                        <span>Fin</span>
                    </div>
                    <div class="cell">
                        <span>T. dedicado</span>
                    </div>
                </div>
                <div
                    class="table-row"
                    *ngFor="let session of jornada.sessions"
                    [attr.data-nav-id]="session.nav_uid"
                    [class.session-active]="session.is_active">
                    <ng-container
                        *ngIf="
                            session.type === 'multi_of_phase_session' ||
                            session.type === 'of_phase_session'
                        ">
                        <div class="cell">
                            <ul>
                                <li *ngFor="let of of session.ofs">
                                    {{ of.linea_composed_id }}
                                </li>
                            </ul>
                        </div>
                        <div class="cell">
                            <div
                                class="of-composed"
                                *ngFor="let of of session.ofs">
                                <div class="uds-span">{{ of.ctd }}uds</div>
                                <div class="client-span">
                                    {{ of.client.name }}
                                </div>
                            </div>
                        </div>
                        <div class="cell d-flex align-items-center">
                            <div
                                class="phase-circle d-flex mr-2"
                                [attr.data-phase-state]="session.phase.state">
                                <svg
                                    [class.phase-disabled]="
                                        !session.phase.enabled
                                    "
                                    [style.height]="'35px'"
                                    viewBox="0 0 31 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        class="phase-circle"
                                        d="M29.9093 15.1934C29.9093 23.0398 23.6019 29.3867 15.8384 29.3867C8.07492 29.3867 1.76758 23.0398 1.76758 15.1934C1.76758 7.34694 8.07492 1 15.8384 1C23.6019 1 29.9093 7.34694 29.9093 15.1934Z"
                                        [attr.stroke]="
                                            getPhaseColor(session.phase.name)
                                        "
                                        stroke-width="2" />
                                    <path
                                        *ngFor="
                                            let index of getPhaseIconPaths(
                                                session.phase.name
                                            )
                                        "
                                        [attr.d]="
                                            getPhaseIcon(session.phase.name)[
                                                index
                                            ]
                                        "
                                        [attr.fill]="
                                            getPhaseColor(session.phase.name)
                                        " />
                                </svg>
                            </div>
                            <span
                                [style.color]="
                                    getPhaseColor(session.phase.name)
                                "
                                >{{ session.phase.name }}</span
                            >
                        </div>
                        <div class="cell date-container">
                            <span class="main">{{
                                session.start_date | localDate: 'HH:mm:ss'
                            }}</span>
                            <br />
                            <span class="secondary">{{
                                session.start_date | localDate: 'd MMM, y'
                            }}</span>
                        </div>
                        <div class="cell date-container">
                            <div *ngIf="!session.is_active">
                                <span class="main">{{
                                    session.end_date | localDate: 'HH:mm:ss'
                                }}</span>
                                <br />
                                <span class="secondary">{{
                                    session.end_date | localDate: 'd MMM, y'
                                }}</span>
                            </div>
                            <div *ngIf="session.is_active">
                                <span class="date">Actuallidad</span>
                            </div>
                        </div>
                        <div class="cell">
                            {{ prettyTimeSring(session.total_time) }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="session.type === 'unlinked_session'">
                        <div class="cell pause-cell">
                            <h4>
                                <i class="fas fa-external-link"></i> Sesón
                                desvinculada
                            </h4>
                        </div>
                        <div class="cell">
                            <strong>Tarea:</strong> {{ session.work_code }}
                            <div *ngIf="session.work_code_extended">
                                <strong>Observaciones:</strong>
                                {{ session.work_code_extended }}
                            </div>
                        </div>
                        <div class="cell"></div>
                        <div class="cell date-container">
                            <span class="main">{{
                                session.start_date | localDate: 'HH:mm:ss'
                            }}</span>
                            <br />
                            <span class="secondary">{{
                                session.start_date | localDate: 'd MMM, y'
                            }}</span>
                        </div>
                        <div class="cell date-container">
                            <div *ngIf="!session.is_active">
                                <span class="main">{{
                                    session.end_date | localDate: 'HH:mm:ss'
                                }}</span>
                                <br />
                                <span class="secondary">{{
                                    session.end_date | localDate: 'd MMM, y'
                                }}</span>
                            </div>
                            <div *ngIf="session.is_active">
                                <span class="date">Actuallidad</span>
                            </div>
                        </div>
                        <div class="cell">
                            {{ prettyTimeSring(session.total_time) }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="session.type === 'work_pause'">
                        <div class="cell pause-cell">
                            <h4>
                                <i class="far fa-pause-circle"></i> Parada
                                Registrada
                            </h4>
                        </div>
                        <div class="cell pause-cell">
                            <strong>Razón:</strong>
                            {{ getReasonFromCode(session.reason_code) }}
                            <div *ngIf="session.reason_extended">
                                <strong>Observaciones:</strong>
                                {{ session.reason_extended }}
                            </div>
                        </div>
                        <div class="cell pause-cell"></div>
                        <div class="cell pause-cell date-container">
                            <span class="main">{{
                                session.start_date | localDate: 'HH:mm:ss'
                            }}</span>
                            <br />
                            <span class="secondary">{{
                                session.start_date | localDate: 'd MMM, y'
                            }}</span>
                        </div>
                        <div class="cell pause-cell date-container">
                            <div *ngIf="!session.is_active">
                                <span class="main">{{
                                    session.end_date | localDate: 'HH:mm:ss'
                                }}</span>
                                <br />
                                <span class="secondary">{{
                                    session.end_date | localDate: 'd MMM, y'
                                }}</span>
                            </div>
                            <div *ngIf="session.is_active">
                                <span class="date">Actuallidad</span>
                            </div>
                        </div>
                        <div class="cell pause-cell">
                            {{ prettyTimeSring(session.total_time) }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
