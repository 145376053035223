import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './components/status-pages/page-not-found/page-not-found.component';

import { LoginComponent } from './components/auth/login/login.component';
import { LostPasswordComponent } from './components/auth/lost-password/lost-password.component';

import { AuthedGuard, NotAuthedGuard } from './guards/auth.guard';

import { OFsViewComponent } from './components/main/ofs-view/ofs-view.component';
import { EmbalajesViewComponent } from './components/main/embalajes-view/embalajes-view.component';
import { StatsViewComponent } from './components/main/stats-view/stats-view.component';

const routes: Routes = [
    {
        path: 'auth/login',
        component: LoginComponent,
        canActivate: [NotAuthedGuard],
    },
    {
        path: 'auth/lost-password',
        component: LostPasswordComponent,
        canActivate: [NotAuthedGuard],
    },
    {
        path: 'ofs',
        component: OFsViewComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'embalajes',
        component: EmbalajesViewComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: 'stats',
        component: StatsViewComponent,
        canActivate: [AuthedGuard],
    },
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [AuthedGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
