<div
    class="modal"
    #camCaptureModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="camCaptureModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered modal-lg"
        role="document">
        <div class="modal-content text-light bg-dark">
            <div class="modal-header">
                <h2 class="modal-title">Capturar imagen</h2>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="webcam-container">
                    <webcam
                        [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)"
                        *ngIf="showWebcam"
                        [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable"
                        [videoOptions]="videoOptions"
                        (cameraSwitched)="cameraWasSwitched($event)"
                        (initError)="handleInitError($event)"></webcam>
                    <img
                        *ngIf="!showWebcam"
                        class="img-fluid"
                        [src]="capturedImage?.imageAsDataUrl"
                        alt="Captured Image" />
                </div>
                <input
                    *ngIf="!showWebcam"
                    type="text"
                    class="form-control mt-2"
                    [(ngModel)]="image_title"
                    disabled="true" />
                <textarea
                    *ngIf="!showWebcam"
                    class="form-control mt-2"
                    [(ngModel)]="image_description"
                    placeholder="Descripción de la imagen"></textarea>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <button
                    type="button"
                    class="btn btn-primary"
                    *ngIf="canCaptureImage"
                    (click)="triggerSnapshot()">
                    Capturar imagen
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    *ngIf="!canCaptureImage"
                    (click)="discardImage()">
                    Descartar imagen
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    *ngIf="canUploadImage"
                    (click)="uploadImage()">
                    Subir imagen
                </button>
            </div>
        </div>
    </div>
</div>
