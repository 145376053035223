<app-top-nav title="Órdenes de fabricación">
    <div class="col-12 col-lg-3">
        <input
            type="text"
            class="form-control"
            placeholder="Buscar por ID..."
            [(ngModel)]="seachFilter"
            (input)="onFiltersChange()"
            (change)="onFiltersChange()" />
    </div>
    <div class="nav-running-container" *ngIf="imRunningEmbalaje">
        <button
            class="btn btn-state btn-pause ml-1"
            (click)="finishMultiOFPhase()"
            [disabled]="disabledInteraction">
            Finalizar Embalaje
        </button>
    </div>
    <div class="nav-running-container" *ngIf="!imPausing">
        <button
            class="btn btn-state btn-pause ml-1"
            (click)="pauseWorkSession()"
            [disabled]="disabledInteraction">
            Parada
        </button>
    </div>
    <div class="nav-running-container" *ngIf="!imRunningUnlinked">
        <button
            class="btn btn-state btn-finish ml-1"
            (click)="runUnlinkedSession()"
            [disabled]="disabledInteraction">
            Iniciar Sesión desvinculada
        </button>
    </div>
    <div class="nav-running-container" *ngIf="imRunningUnlinked">
        <button
            class="btn btn-danger btn-state ml-1"
            (click)="stopUnlinkedSession()"
            [disabled]="disabledInteraction">
            Finalizar Sesión desvinculada
        </button>
    </div>
</app-top-nav>

<div class="custom-table">
    <div class="table-body">
        <div class="table-row table-cols pt-4">
            <div class="cell">
                <span
                    class="sorting"
                    [class.sorting_desc]="sortings['linea_composed_id'] === -1"
                    [class.sorting_asc]="sortings['linea_composed_id'] === 1"
                    (click)="sortBy('linea_composed_id')"
                    >Orden</span
                >
            </div>
            <div class="cell">
                <span>Fases</span>
            </div>
            <div class="cell">
                <span
                    class="sorting"
                    [class.sorting_desc]="sortings['plazo'] === -1"
                    [class.sorting_asc]="sortings['plazo'] === 1"
                    (click)="sortBy('plazo')"
                    >Fecha Límite</span
                >
            </div>
        </div>
        <div
            class="table-row"
            [class.of-expanded]="expandedOF_UID == _of.uid"
            *ngFor="let _of of ofItemList_filtered; let i = index; trackBy: trackByFn">
            <div class="cell" (click)="expandToggle(_of)">
                <div class="of-data">
                    <div class="of-composed">
                        <strong>{{ _of.linea_composed_id }}</strong>
                        <div class="uds-span">{{ _of.ctd }}uds</div>
                        <div class="client-span">{{ _of.client.name }}</div>
                    </div>
                    <div class="descr-art">{{ _of.descrip_articulo }}</div>
                </div>
            </div>
            <div class="cell" (click)="expandToggle(_of)">
                <app-phase-container
                    [height]="40"
                    [phaseList]="_of.phases"></app-phase-container>
            </div>
            <div class="cell date-container" (click)="expandToggle(_of)">
                <span class="date">{{ _of.plazo | date: 'd MMM, y' }}</span>
                <!--<br>
                <span class="hour">{{_of.plazo | date:'HH:mm'}}</span>-->
            </div>
            <div
                class="cell-full-wide of-content"
                *ngIf="expandedOF_UID == _of.uid">
                <div class="of-tabs">
                    <div
                        class="of-tab of-tab-runtime"
                        [class.tab-active]="ofTab === 'runtime'"
                        (click)="setOfTab('runtime')">
                        <span>Ejecución</span>
                    </div>
                    <div
                        class="of-tab of-tab-files"
                        [class.tab-active]="ofTab === 'files'"
                        (click)="setOfTab('files')">
                        <span>Ficheros</span>
                    </div>
                </div>
                <div *ngIf="ofTab === 'runtime'">
                    <app-phase-container
                        [height]="65"
                        [selectMode]="true"
                        [showTitles]="true"
                        [phaseList]="_of.phases"
                        [selectedPhaseUID]="selectedPhaseUID"
                        (phaseSelected)="phaseSelected(_of, $event)"
                        (phaseDeselected)="phaseDeselected(_of)">
                    </app-phase-container>
                    <div class="of-state-container" *ngIf="expandedOFPhase">
                        <div
                            class="state-title"
                            [attr.data-state]="expandedOFPhaseLocalState">
                            <span>{{
                                state_titles[expandedOFPhaseLocalState]
                            }}</span>
                        </div>
                        <div
                            class="state-content"
                            *ngIf="expandedOFPhaseLocalState === 'not_started'">
                            <button
                                class="btn btn-state btn-start"
                                (click)="startOFPhase(_of, expandedOFPhase)"
                                [disabled]="disabledInteraction">
                                Iniciar
                            </button>
                        </div>
                        <div
                            class="state-content"
                            *ngIf="expandedOFPhaseLocalState === 'in_progress'">
                            <button
                                class="btn btn-state btn-finish"
                                (click)="finishOFPhase(_of, expandedOFPhase)"
                                [disabled]="disabledInteraction">
                                Finalizar
                            </button>
                        </div>
                        <div
                            class="state-content"
                            *ngIf="
                                expandedOFPhaseLocalState === 'paused' ||
                                extendedOfImPausing
                            ">
                            <button
                                class="btn btn-state btn-resume"
                                (click)="resumeOFPhase(_of, expandedOFPhase)"
                                [disabled]="disabledInteraction">
                                Reanudar
                            </button>
                            <button
                                class="btn btn-state btn-finish"
                                (click)="finishOFPhase(_of, expandedOFPhase)"
                                [disabled]="disabledInteraction">
                                Finalizar
                            </button>
                        </div>
                        <div
                            class="state-content"
                            *ngIf="expandedOFPhaseLocalState === 'completed'">
                            <button
                                class="btn btn-state btn-resume"
                                (click)="startOFPhase(_of, expandedOFPhase)"
                                [disabled]="disabledInteraction">
                                Reanudar
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="ofTab === 'files'">
                    <app-file-manager
                        [article_id]="_of.id_articulo"
                    ></app-file-manager>
                </div>
            </div>
        </div>
    </div>
</div>

<app-of-pause-modal
    #pauseModal
    [disabledInteraction]="disabledInteraction"
    (disabledInteractionBind)="disabledInteraction = $event">
</app-of-pause-modal>

<app-unlinked-session-modal
    #unlinkedSessionModal
    [disabledInteraction]="disabledInteraction"
    (disabledInteractionBind)="disabledInteraction = $event">
</app-unlinked-session-modal>
