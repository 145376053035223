export const MS = {
    inSecond: 1000,
    inMinute: 1000 * 60,
    inHour: 1000 * 60 * 60,
    inDay: 1000 * 60 * 60 * 24,
};

export const PHASE_COLORS: any = {
    bobinado: '#f9c74f',
    montaje: '#f8961e',
    barnizado: '#f9844a',
    tropicalizado: '#f3722c',
    conexionado: '#f94144',
    ensayo: '#90be6d',
    embalaje: '#43aa8b',
};
