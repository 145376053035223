import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

const API_URL = environment.API_URL;

@Injectable({
    providedIn: 'root',
})
export class APIService {
    base_url: string = API_URL;

    constructor(private http: HttpClient) {}

    url(path: string) {
        return `${this.base_url}${path}`;
    }

    getActiveAuthToken() {
        return localStorage.getItem('active_auth_token');
    }

    getAuthHeaders() {
        const active_auth_token = this.getActiveAuthToken();
        if (!active_auth_token) return;

        return {
            Authorization: `Bearer ${active_auth_token}`,
        };
    }

    get(path: string, params: any = {}, authed = true) {
        return new Promise((resolve, reject) => {
            const options: any = {};
            if (authed)
                options['headers'] = new HttpHeaders(this.getAuthHeaders());
            if (params) options['params'] = params;

            this.http.get(this.url(path), options).subscribe(
                (res: any) => resolve(res),
                (err: any) => reject(err)
            );
        });
    }

    post(path: string, params: any = {}, authed = true) {
        return new Promise((resolve, reject) => {
            const options: any = {};
            if (authed)
                options['headers'] = new HttpHeaders(this.getAuthHeaders());

            this.http.post(this.url(path), params, options).subscribe(
                (res: any) => resolve(res),
                (err: any) => reject(err)
            );
        });
    }
}

export default APIService;
