<div
    class="modal"
    #ofPauseModal
    tabindex="-1"
    role="dialog"
    aria-labelledby="ofPauseModal"
    aria-hidden="true">
    <div
        *ngIf="showing"
        class="modal-dialog modal-dialog-centered"
        role="document">
        <div class="modal-content text-light bg-dark">
            <div class="modal-header">
                <h2 class="modal-title">Razón de pausa:</h2>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button
                            class="btn btn-pause-opt"
                            [class.active]="selected_opt === 'fin_jornada'"
                            (click)="setOpt('fin_jornada')">
                            Fin de Jornada
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-pause-opt"
                            [class.active]="selected_opt === 'comida'"
                            (click)="setOpt('comida')">
                            Comida
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-pause-opt"
                            [class.active]="selected_opt === 'medico'"
                            (click)="setOpt('medico')">
                            Médico
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-pause-opt"
                            [class.active]="
                                selected_opt === 'descanso_almuerzo'
                            "
                            (click)="setOpt('descanso_almuerzo')">
                            Descanso/Almuerzo
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <button
                            class="btn btn-pause-opt"
                            [class.active]="selected_opt === 'otra'"
                            (click)="setOpt('otra')">
                            Otra
                        </button>
                    </div>
                </div>
                <hr />
                <textarea
                    class="form-control"
                    rows="3"
                    [disabled]="!selected_opt"
                    [(ngModel)]="selected_opt_extra"
                    placeholder="Observaciones..."></textarea>
            </div>
            <div class="modal-footer">
                <button
                    class="btn btn-pause"
                    (click)="pauseWorkSession()"
                    [disabled]="!selected_opt">
                    REGISTRAR PARADA
                </button>
            </div>
        </div>
    </div>
</div>
