import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { FileManagerService } from '../../../../services/main/file-manager.service';
import File from 'src/app/models/File';
import { CameraCaptureModalComponent } from '../../camera-capture-modal/camera-capture-modal.component';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent implements OnChanges {
    @Input() article_id!: string;

    constructor(private fileManagerService: FileManagerService) {}

    path: string[] = [];
    files: File[] = [];
    ngOnChanges(changes: SimpleChanges) {
        this.setPath([]);
    }

    updateFiles() {
        return new Promise(resolve => {
            this.fileManagerService
                .getArticleFiles(this.article_id, this.path)
                .then(data => {
                    if (!data) return resolve(false);

                    const { isFile } = data;
                    if (isFile) {
                        const { file } = data;
                        if (!file) return resolve(false);
                        this.files = [];
                        this.isPathFile = true;
                        this.pathFile = file;
                        this.isPDFLoaded = false;
                    } else {
                        const { files } = data;
                        if (!files) return resolve(false);
                        this.files = files;
                        this.isPathFile = false;
                        this.isPDFLoaded = false;
                    }

                    resolve(true);
                });
        });
    }

    isPathFile = false;
    pathFile!: File;
    updatingPath = true;
    async setPath(path: string[]) {
        this.path = path;

        this.updatingPath = true;

        const succeed = await this.updateFiles();
        if (!succeed) {
            this.path = this.path.slice(0, this.path.length - 1);
        }

        this.updatingPath = false;
    }

    isImage(file: File) {
        return ['.png', '.jpg', '.jpeg', '.gif'].includes(file.extension.toLowerCase());
    }

    isPDFLoaded = false;
    pdfLoaded() {
        this.isPDFLoaded = true;
    }

    getServeFile(file: File) {
        const path = [...(file.path || [])];
        if (!path) return '';

        const fileName = path.splice(path.length - 1, 1)[0];
        let encoded_path = path.join('<>');
        if (encoded_path === '') encoded_path = '<>';

        return (
            this.fileManagerService.getServePath() +
            `${file.authToken}/${encodeURIComponent(
                this.article_id
            )}/${encodeURIComponent(encoded_path)}/${encodeURIComponent(
                fileName
            )}`
        );
    }

    @ViewChild('camCaptureModal') camCaptureModal!: CameraCaptureModalComponent;
    openCapCaptureModal() {
        this.camCaptureModal.openModal();
    }

    fileClicked(file: File) {
        if (this.updatingPath) return;

        this.setPath([...this.path, file.name]);
    }

    backFolder(index: number) {
        this.setPath(this.path.slice(0, index + 1));
    }
}
