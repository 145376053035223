<div class="auth-form-container">
    <div class="auth-form auth-form-recover">
        <img src="assets/logos/eremu-auth.png" alt="logo" class="logo" />
        <form id="recover-form">
            <h4 class="auth-title">Recuperar contraseña</h4>
            <p class="auth-sub">
                Contacta con un administrador para recuperar tu contraseña
            </p>

            <a routerLink="/auth/login"
                ><button class="btn-auth btn-auth-login">
                    Iniciar sesión
                </button></a
            >
        </form>
    </div>
</div>
