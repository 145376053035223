import { Component } from '@angular/core';

@Component({
    selector: 'app-lost-password',
    templateUrl: './lost-password.component.html',
    styleUrls: ['../auth.component.scss', './lost-password.component.scss'],
})
export class LostPasswordComponent {
    constructor() {}
}
