import {
    Component,
    ElementRef,
    AfterViewInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { WebcamComponent, WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import FileManagerService from '../../../services/main/file-manager.service';

declare let $: any;

@Component({
    selector: 'app-camera-capture-modal',
    templateUrl: './camera-capture-modal.component.html',
    styleUrls: ['./camera-capture-modal.component.scss'],
})
export class CameraCaptureModalComponent implements AfterViewInit {
    showing = false;

    @Input()
    disabledInteraction = false;

    @Output()
    disabledInteractionBind = new EventEmitter<boolean>();

    @Output()
    public imageUploaded = new EventEmitter<boolean>();

    @ViewChild('camCaptureModal') camCaptureModal!: ElementRef;

    constructor(private fileManagerService: FileManagerService) {}

    @Input()
    article_id!: string;

    @Input()
    path: string[] = [];

    camCaptureModalQ: any;
    ngAfterViewInit() {
        this.camCaptureModalQ = $(this.camCaptureModal.nativeElement)
            .on('show.bs.modal', () => (this.showing = true))
            .on('hidden.bs.modal', () => (this.showing = false));

        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            }
        );
    }

    canCaptureImage = false;
    canUploadImage = false;

    openModal() {
        this.camCaptureModalQ.modal('show');

        this.showWebcam = true;
        this.canCaptureImage = true;
        this.canUploadImage = false;

        this.image_title = '';
        this.image_description = '';
    }

    @Output()
    public pictureTaken = new EventEmitter<WebcamImage>();

    // toggle webcam on/off
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId!: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
        facingMode: 'environment',
    };
    public errors: WebcamInitError[] = [];

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<
        boolean | string
    >();

    public triggerSnapshot(): void {
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
        // Swetch to next camera
        // this.deviceId = this.deviceId === '1' ? '2' : '1';
    }

    public image_title!: string;
    public image_description!: string;
    capturedImage!: WebcamImage;
    public handleImage(webcamImage: WebcamImage): void {
        this.showWebcam = false;
        this.canCaptureImage = false;
        this.canUploadImage = true;
        this.capturedImage = webcamImage;
        this.pictureTaken.emit(webcamImage);

        const n = new Date();
        this.image_title = `IMG_${n.getFullYear()}-${n.getMonth()}-${n.getDate()}_${n.getDate()}-${n.getHours()}-${n.getMinutes()}-${n.getSeconds()}`;
        this.image_description = '';
    }

    public cameraWasSwitched(deviceId: string): void {
        console.log('active device: ' + deviceId);
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    public discardImage() {
        this.showWebcam = true;
        this.canCaptureImage = true;
        this.canUploadImage = false;
    }

    public uploadImage() {
        this.camCaptureModalQ.modal('hide');

        this.fileManagerService
            .uploadImageCapture(
                this.article_id,
                this.path,
                this.image_title,
                this.image_description,
                this.capturedImage.imageAsDataUrl
            )
            .then((res) => {
                this.imageUploaded.emit(true);
            });
    }
}
