import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';

import APIService from './api.service';
import AuthService from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class MeService extends EventTarget {
    constructor(
        private api: APIService,
        private authService: AuthService
    ) {
        super();

        this.authService.addEventListener('authed', () => this.clear());
        this.authService.addEventListener('unauthed', () => this.clear());
    }

    cachedBasic: User | null = null;
    getBasic(): Promise<User | false> {
        return new Promise(resolve => {
            if (this.cachedBasic) return resolve(this.cachedBasic);
            this.api
                .get('/me/basic/')
                .then((res: any) => {
                    const { success, data } = res;
                    if (!success) return resolve(false);

                    const user = data as User;
                    this.cachedBasic = user;

                    this.triggerEvent('ready');

                    return resolve(user);
                })
                .catch(err => resolve(false));
        });
    }

    triggerEvent(event: any, data: any = null) {
        const eventObject = new CustomEvent(event, { detail: data });
        this.dispatchEvent(eventObject);
    }

    clear() {
        this.cachedBasic = null;
    }

    log(...args: any[]) {
        console.debug('[MeService]', ...args);
    }
}

export default MeService;
